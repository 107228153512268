<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(changePassword)">
        <slot></slot>
        <ValidationProvider
          v-if="askOldPassword"
          rules="required"
          vid="oldPassword"
          v-slot="{ errors, valid }"
        >
          <b-field
            :label="$t('password_change.label.old_password')"
            :message="errors"
            :type="{ 'is-danger': errors[0], '': valid }"
            :custom-class="labelClass"
          >
            <b-input
              v-model="oldPassword"
              type="password"
              icon="lock"
              placeholder="Password"
            ></b-input>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          rules="required"
          vid="password"
          v-slot="{ errors, valid }"
        >
          <b-field
            :label="$t('password_change.label.new_password')"
            :message="errors"
            :type="{ 'is-danger': errors[0], '': valid }"
            :custom-class="labelClass"
          >
            <b-input
              v-model="newPassword"
              type="password"
              icon="lock"
              placeholder="Password"
            ></b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          name="confirm"
          rules="required|confirmed:password"
          vid="confirmation"
          v-slot="{ errors, valid }"
        >
          <b-field
            :label="$t('password_change.label.confirm_new_password')"
            :message="errors"
            :type="{ 'is-danger': errors[0], '': valid }"
            :custom-class="labelClass"
          >
            <b-input
              v-model="confirmNewPassword"
              type="password"
              icon="lock"
              placeholder="Password"
              lazy
            ></b-input>
          </b-field>
        </ValidationProvider>

        <b-button native-type="submit" class="is-primary mt-3">{{
          $t("password_change.buttons.change")
        }}</b-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    askOldPassword: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  watch: {},
  methods: {
    changePassword() {
      console.log("change");
      if (!this.askOldPassword || this.oldPassword) {
        if (this.newPassword && this.confirmNewPassword) {
          if (this.newPassword === this.confirmNewPassword) {
            this.$emit("change-password", this.oldPassword, this.newPassword);
            this.resetFields();

            // Wait until the models are updated in the UI
            this.$nextTick(() => {
              this.$refs.form.reset();
            });
          }
        }
      }
    },
    resetFields() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    },
  },
};
</script>

<style></style>
